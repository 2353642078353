<template>
  <GlobalDialog class="flex flex-col gap-4 w-[450px] min-h-[400px] dialog avv-gray-100 avv-bg">
    <h2 class="font-bold" v-text="localize(`from_doc.dialog.title.${dialogData.file.errors ? 'error' : 'default'}`)" />

    <div class="flex-grow flex flex-col">
      <!-- File with light blue background -->
      <File v-if="dialogData.file.file" :file_name="dialogData.file.name" class="bg-primary-150 rounded-lg p-4"
        @close="retry" />

      <!-- Loading state -->
      <div v-if="dialogData.ai.loading" class="flex-grow flex flex-col items-center justify-center">
        <AIComments :logs="dialogData.ai.logs" :loading="dialogData.ai.loading" :error="dialogData.ai.error" />
        <p class="mt-2 text-neutral-400">{{ localize('generating_message') }}</p>
      </div>
      <!-- Ready state with static text -->
      <div v-else-if="dialogData.ai.entriesCreated"
        class="flex-grow flex flex-col items-center justify-center text-center">
        <p class="text-neutral-700">{{ localize('document_ready') }}</p>
        <p class="text-neutral-500">{{ localize('click_next') }}</p>
      </div>
      <!-- Initial state -->
      <div v-else>
        <!-- Errors -->
        <Errors v-if="dialogData.file.errors" :errors="dialogData.file.errors" />
        <!-- File upload-->
        <FileUpload v-if="fileUpoad" :height200px="dialogData.ai.logs.length < 1" :readyForUpload="fileUpoad"
          :loading="dialogData.file.loading" type="docx" @upload="chooseFile">
          <span class="font-bold avv-primary-color" v-text="localize('from_doc.dialog.dropzone.label')" />
          {{ localize('from_doc.dialog.dropzone.text') }}
        </FileUpload>
        <!-- File -->
        <File v-else-if="dialogData.ai.logs.length > 0 && !dialogData.file.errors" :file_name="dialogData.file.name"
          class="bg-primary-150 rounded-lg p-4" @close="retry" />
      </div>
    </div>

    <!-- Toggle switch -->
    <div class="flex items-center">
      <AToggle :active="dialogData.ai.enforceAnswers" :title="localize('from_doc.dialog.toggle')"
        @click="toggleEnforceAnswers" />
    </div>

    <!-- Buttons -->
    <div class="flex justify-between mt-auto">
      <div>
        <PreStyleButton mode="primary_full" @click="close">
          {{ localize('from_doc.dialog.cancel') }}
        </PreStyleButton>
      </div>
      <div class="flex gap-x-2.5">
        <PreStyleButton v-if="buttonRetryShow" mode="primary_full" @click="retry">
          {{ localize('from_doc.dialog.retry') }}
        </PreStyleButton>
        <PreStyleButton v-if="!dialogData.ai.entriesCreated" mode="primary_full"
          :disable="buttonGenerateEntriesDisable || dialogData.ai.loading" @click="sendDocToAI">
          {{ localize('from_doc.dialog.next') }}
        </PreStyleButton>
        <PreStyleButton v-else mode="primary_full" :disable="dialogData.ai.loading || dialogData.ai.redirecting"
          @click="createDoc">
          <template v-if="dialogData.ai.redirecting">
            <span class="flex items-center gap-2">
              <span class="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
              {{ localize('redirecting') }}
            </span>
          </template>
          <template v-else>
            {{ localize('from_doc.dialog.next') }}
          </template>
        </PreStyleButton>
      </div>
    </div>
  </GlobalDialog>
</template>

<script lang="ts" setup>
import { defineComponent, ref, computed } from 'vue'
import AiApi from '@api/AiApi'
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import PreStyleButton from '../_abstract/PreStyleButton.vue'
import Errors from './components/Errors.vue'
import File from './components/File.vue'
import AIComments from './components/AIComments.vue'
import FileUpload from './components/FileUpload.vue'
import AToggle from '../_abstract/AToggle.vue'
import { createLog, createAndSubmitForm } from './utils'
import type { AIDialogFromDocData } from './utils'

const props = defineProps<{
  templates?: number[]
}>()

const emit = defineEmits<{
  (e: 'callback'): void
}>()

const dialogData = ref<AIDialogFromDocData>({
  file: {
    name: '',
    file: null,
    loading: false,
    link: '',
    size: null,
    errors: null
  },
  ai: {
    logs: [],
    loading: false,
    firstTime: true,
    entriesCreated: false,
    error: false,
    entries: {},
    enforceAnswers: false,
    redirecting: false,
    document_id: null,
    participant_id: null
  }
})

//File Upoad conditions

const fileUpoad = computed(() => {
  return (!dialogData.value.file.file || dialogData.value.ai.entriesCreated || dialogData.value.file.loading) && !dialogData.value.file.errors
})

//Buttons conditions

const buttonRetryShow = computed(() => {
  return dialogData.value.file.file && dialogData.value.file.errors
})
const buttonGenerateEntriesDisable = computed(() => {
  return !!(!dialogData.value.file.file || dialogData.value.file.errors)
})

//Actions

const close = () => {
  emit('callback')
}

const retry = () => {
  dialogData.value.file.name = ''
  dialogData.value.file.file = null
  dialogData.value.file.errors = null
  dialogData.value.file.link = ''
  dialogData.value.file.size = null
  dialogData.value.ai.entriesCreated = false
  dialogData.value.ai.entries = {}
  dialogData.value.ai.error = false
}

const toggleEnforceAnswers = () => {
  dialogData.value.ai.enforceAnswers = !dialogData.value.ai.enforceAnswers
}

const chooseFile = (e: Event) => {
  retry()

  const input = e.target as HTMLInputElement
  dialogData.value.file.file = input.files![0] as File

  if (dialogData.value.file.file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    dialogData.value.file.errors = { file_generic: true }
    return console.warn('File type mismatch')
  }

  if (dialogData.value.file.file.size > 256e6) {
    dialogData.value.file.errors = { file_large: true }
    return console.warn('File too large')
  }
  dialogData.value.file.name = dialogData.value.file.file.name
}

const sendDocToAI = () => {
  dialogData.value.ai.loading = true
  if (dialogData.value.ai.firstTime) dialogData.value.ai.firstTime = false

  const data = {
    file: dialogData.value.file.file,
    template_id: props.templates![0],
    enforce_answers: dialogData.value.ai.enforceAnswers
  }

  AiApi.docxToEntries({
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then((response) => {
      console.log(response)
      dialogData.value.ai.document_id = response.document_id
      dialogData.value.ai.participant_id = response.participant_id
      dialogData.value.ai.loading = false
      dialogData.value.ai.entriesCreated = true
    })
    .catch((e) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.error = true
    })
}

const createDoc = () => {
  dialogData.value.ai.redirecting = true
  window.location.href = '../documents/' + dialogData.value.ai.document_id + '/questionnaire?participant_id=' + dialogData.value.ai.participant_id
}

const localize = (key: string) => window.localizeText(`documents.ai_create.${key}`)
</script>

<script lang="ts">
export default defineComponent({ name: 'AICreateFromDocDialog' })
</script>